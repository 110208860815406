import { ICategory, NCategory } from "../../interfaces";
import BaseApi from "./baseApi.service";

export class StoreCategoryService extends BaseApi {
    private static instance: StoreCategoryService;
    private static store?: string;
    private customHeader = () => {
        const customHeader: Record<string, string> = {};
        if (StoreCategoryService.store) {
            customHeader["x-tenant-slug"] = StoreCategoryService.store;
        }
        return customHeader;
    };
    constructor() {
        super("market");
    }

    public static getInstance(): StoreCategoryService {
        if (!StoreCategoryService.instance) {
            StoreCategoryService.instance = new StoreCategoryService();
        }
        return StoreCategoryService.instance;
    }

    public static resetInstance(): void {
        this.instance = new StoreCategoryService();
    }

    private readonly END_POINT = {
        storeCategory: "v1/category",
    };

    public async getAll(): Promise<NCategory.Category[]> {
        const data = await this.get<NCategory.Category[]>(
            this.END_POINT.storeCategory,
            {},
            this.customHeader(),
        );
        if (data?.data) {
            return data?.data;
        }
        return [];
    }
}
