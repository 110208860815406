import { NDiscount } from "@interfaces/components/discount";
import Response from "../../data-access/response/response";
import BaseApi from "./baseApi.service";

export class DiscountService extends BaseApi {
    private static instance: DiscountService;
    private static store?: string;
    private customHeader = () => {
        const customHeader: Record<string, string> = {};
        if (DiscountService.store) {
            customHeader["x-tenant-slug"] = DiscountService.store;
        }
        return customHeader;
    };

    constructor(store?: string) {
        super();
        DiscountService.store = store;
    }
    private readonly END_POINT = {
        discount: "v1/public/discount",
        discounts: "v1/public/discounts",
    };

    public static getInstance(): DiscountService {
        if (!DiscountService.instance) {
            DiscountService.instance = new DiscountService();
        }
        return DiscountService.instance;
    }

    public static resetInstance(store?: string): void {
        this.instance = new DiscountService(store);
    }

    public async getAllCartRuleDiscountsByShopId(
        shopId: string,
    ): Promise<NDiscount.IDiscountDetail[] | null> {
        const result = await this.get<Response<NDiscount.IDiscountDetail[]>>(
            `${this.END_POINT.discounts}/${shopId}`,
            {},
            this.customHeader(),
        );
        if (!result?.data) {
            return null;
        }

        return result?.data?.data;
    }

    public async getDiscountById(
        id: string,
    ): Promise<NDiscount.IDiscountDetail | null> {
        const result = await this.get<Response<NDiscount.IDiscountDetail>>(
            `${this.END_POINT.discount}/${id}`,
            {},
            this.customHeader(),
        );
        if (!result?.data) {
            return null;
        }

        return result?.data?.data;
    }
}
