export class CartItem {
    id: string;
    cartId: string;
    orderId: string;
    title: string;
    description: string;
    imageUrl: string;
    quantity: string;
    variantId: string;

    constructor(data?: any) {
        this.id = data?.id || "";
        this.cartId = data?.cart_id || "";
        this.orderId = data?.order_id || "";
        this.title = data?.title || "";
        this.description = data?.description || "";
        this.imageUrl = data?.thumbnail || "";
        this.quantity = data?.quantity || "";
        this.variantId = data?.variant_id || "";
    }
}
