import { Cart } from "../../data-access/models/cart.model";
import { Order } from "../../data-access/models/order.model";
import {
    IAddItemToCart,
    IDeleteItemInCart,
    ITaxes,
    IUpdateItemInCart,
} from "../../interfaces";
import BaseApi from "./baseApi.service";

export class CartService extends BaseApi {
    private static instance: CartService;
    private static store?: string;
    private customHeader = () => {
        const customHeader: Record<string, string> = {};
        if (CartService.store) {
            customHeader["x-tenant-slug"] = CartService.store;
        }
        return customHeader;
    };

    constructor(store?: string) {
        super();
        CartService.store = store;
    }

    public static getInstance(): CartService {
        if (!CartService.instance) {
            CartService.instance = new CartService();
        }
        return CartService.instance;
    }
    public static resetInstance(store?: string): void {
        this.instance = new CartService(store);
    }

    private readonly END_POINT = {
        cart: "carts",
    };

    public async getCart(id: string): Promise<Cart | null> {
        const { data } = await this.get<any>(
            `${this.END_POINT.cart}/${id}`,
            {},
            this.customHeader(),
        );
        if (data && data.cart) {
            return new Cart(data.cart);
        }
        return null;
    }

    public async create(): Promise<Cart | null> {
        const { data } = await this.post<any>(
            this.END_POINT.cart,
            {},
            {},
            this.customHeader(),
        );
        if (data && data.cart) {
            return new Cart(data.cart);
        }
        return null;
    }

    public async addItemToCart(model: IAddItemToCart): Promise<Cart | null> {
        const payload = {
            variant_id: model.itemId,
            quantity: model.quantity,
        };

        const { data } = await this.post<any>(
            `${this.END_POINT.cart}/${model.cartId}/line-items`,
            payload,
            {},
            this.customHeader(),
        );
        if (data && data.cart) {
            return new Cart(data.cart);
        }
        return null;
    }

    public async updateItem(model: IUpdateItemInCart): Promise<Cart | null> {
        const payload = {
            quantity: model.quantity,
        };

        const { data } = await this.post<any>(
            `${this.END_POINT.cart}/${model.cartId}/line-items/${model.itemId}`,
            payload,
            {},
            this.customHeader(),
        );
        if (data && data.cart) {
            return new Cart(data.cart);
        }
        return null;
    }

    public async deleteItem(model: IDeleteItemInCart): Promise<Cart | null> {
        const { data } = await this.delete<any>(
            `${this.END_POINT.cart}/${model.cartId}/line-items/${model.itemId}`,
            {},
            this.customHeader(),
        );
        if (data && data.cart) {
            return new Cart(data.cart);
        }
        return null;
    }

    public async completeCart(id: string): Promise<Order | null> {
        const { data } = await this.post<any>(
            `${this.END_POINT.cart}/${id}/complete`,
            {},
            {},
            this.customHeader(),
        );
        if (data && data.data) {
            return new Order(data.data);
        }
        return null;
    }

    // public async updateShippingAddress(model: IGuest): Promise<Cart | null> {
    //     const payload = {
    //         shipping_address: {
    //             first_name: model.shippingAddress?.name,
    //             phone: model.shippingAddress?.phone,
    //             booking_slot: model.shippingAddress?.bookingSlot,
    //             city: model.shippingAddress?.city,
    //             city_id: model.shippingAddress?.cityId,
    //             district: model.shippingAddress?.district,
    //             district_id: model.shippingAddress?.districtId,
    //             ward: model.shippingAddress?.ward,
    //             ward_id: model.shippingAddress?.wardId,
    //             address1: model.shippingAddress?.street,
    //         },
    //     };
    //     const { data } = await this.put<any>(
    //         `${this.END_POINT.cart}/${model.cartId}`,
    //         payload,
    //     );
    //     if (data) {
    //         return new Cart(data);
    //     }
    //     return null;
    // }

    public async removeAllItem(cartId: string): Promise<boolean> {
        const cart = await this.getCart(cartId);
        if (cart) {
            if (cart.items && cart.items.length) {
                for (const item of cart.items) {
                    const result = await this.deleteItem({
                        cartId,
                        itemId: item.id,
                    });
                    if (!result) return false;
                }
                return true;
            }
            return true;
        } else {
            return false;
        }
    }

    public async calculateTaxes(cartId: string): Promise<ITaxes | null> {
        const { data } = await this.post<any>(
            `${this.END_POINT.cart}/${cartId}/taxes`,
            {},
            {},
            this.customHeader(),
        );
        if (data && data.cart) {
            const cart = new Cart(data.cart);
            return {
                total: cart.total,
                subtotal: cart.subtotal,
            };
        }
        return null;
    }

    public async selectPaymentSession(cartId: string): Promise<boolean> {
        const { data } = await this.post<any>(
            `${this.END_POINT.cart}/${cartId}/payment-sessions`,
            {
                provider_id: "manual",
            },
            {},
            this.customHeader(),
        );
        if (data && data.cart) {
            return true;
        }
        return false;
    }
}
