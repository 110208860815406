import BaseApi from "./baseApi.service";
import { NPaymentSetting } from "@interfaces/components/payment-setting";
import Response from "@data-access/response/response";

export class PaymentSettingService extends BaseApi {
    private static instance: PaymentSettingService;
    private static store?: string;
    private customHeader = () => {
        const customHeader: Record<string, string> = {};
        if (PaymentSettingService.store) {
            customHeader["x-tenant-slug"] = PaymentSettingService.store;
        }
        return customHeader;
    };

    constructor(store?: string) {
        super();
        PaymentSettingService.store = store;
    }

    public static getInstance(): PaymentSettingService {
        if (!PaymentSettingService.instance) {
            PaymentSettingService.instance = new PaymentSettingService();
        }
        return PaymentSettingService.instance;
    }
    public static resetInstance(store?: string): void {
        this.instance = new PaymentSettingService(store);
    }

    private readonly END_POINT = "v1/public/payment-setting";

    public async getShopPaymentSettings(
        shopId: string,
    ): Promise<NPaymentSetting.IPaymentSettingDetail | null> {
        const result = await this.get<
            Response<NPaymentSetting.IPaymentSettingDetail>
        >(`${this.END_POINT}/${shopId}`, {}, this.customHeader());
        return result?.data?.data || null;
    }

    public async getTipOptions(shopId: string): Promise<number[] | null> {
        const result = await this.get<any>(
            `${this.END_POINT}/${shopId}`,
            {},
            this.customHeader(),
        );

        return result?.data?.data?.tipOptions || null;
    }
}
