import { Collection } from "../../data-access/models/collection.model";
import BaseApi from "./baseApi.service";

export class CollectionService extends BaseApi {
    private static instance: CollectionService;
    private static store?: string;
    private customHeader = () => {
        const customHeader: Record<string, string> = {};
        if (CollectionService.store) {
            customHeader["x-tenant-slug"] = CollectionService.store;
        }
        return customHeader;
    };

    constructor(store?: string) {
        super();
        CollectionService.store = store;
    }

    public static getInstance(): CollectionService {
        if (!CollectionService.instance) {
            CollectionService.instance = new CollectionService();
        }
        return CollectionService.instance;
    }

    public static resetInstance(store?: string): void {
        this.instance = new CollectionService(store);
    }

    private readonly END_POINT = {
        collection: "collections",
    };

    public async getCollection(): Promise<Collection[]> {
        const { data } = await this.get<any>(
            this.END_POINT.collection,
            {},
            this.customHeader(),
        );
        if (data && data.collections) {
            const result: Collection[] = [];
            data.collections.forEach((collection: Partial<Collection>) => {
                result.push(new Collection(collection));
            });
            return result;
        }
        return [];
    }
}
