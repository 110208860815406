import BaseApi from "./baseApi.service";
import { NProduct } from "../../interfaces/components/product";
import Response from "../../data-access/response/response";

export class ProductService extends BaseApi {
    private static instance: ProductService;
    private static store?: string;
    private customHeader = () => {
        const customHeader: Record<string, string> = {};
        if (ProductService.store) {
            customHeader["x-tenant-slug"] = ProductService.store;
        }
        return customHeader;
    };

    constructor(store?: string) {
        super();
        ProductService.store = store;
    }

    public static getInstance(): ProductService {
        if (!ProductService.instance) {
            ProductService.instance = new ProductService();
        }
        return ProductService.instance;
    }

    public static resetInstance(store?: string): void {
        this.instance = new ProductService(store);
    }

    private readonly END_POINT = {
        product: "v1/public/product",
        products: "v1/public/products",
        configProducts: "v1/public/configed-products",
    };

    public async getProducts(
        params?: NProduct.IGetProduct,
    ): Promise<NProduct.IPublicProduct[]> {
        const { data } = await this.get<Response<NProduct.IPublicProduct[]>>(
            this.END_POINT.products,
            params as any,
            this.customHeader(),
        );
        if (data?.data?.length) {
            return data?.data;
        }
        return [];
    }

    public async getProductDetail(
        query: NProduct.IGetPublicProduct,
    ): Promise<NProduct.IProduct | null> {
        const { data } = await this.get<Response<NProduct.IProduct>>(
            `${this.END_POINT.product}`,
            query as any,
            this.customHeader(),
        );
        if (data?.data) {
            return data?.data;
        }
        return null;
    }

    public async getConfigProductsByProductId(
        request: NProduct.IGetProductConfigRequestDto,
        productId: string,
    ): Promise<NProduct.IProduct[]> {
        const { data } = await this.get<Response<NProduct.IProduct[]>>(
            `${this.END_POINT.configProducts}/${productId}`,
            request as any,
            this.customHeader(),
        );
        if (data?.data?.length) {
            return data?.data;
        }
        return [];
    }
}
