import BaseApi from "./baseApi.service";
import { NShippingSetting } from "@interfaces/components/shipping-setting";
import Response from "@data-access/response/response";

export interface ILatLng {
    lat: number;
    lng: number;
}
export interface IGetEstimateShippingFeeRequestDto extends Partial<ILatLng> {
    shopId: string;
    address?: string;
}
export class ShippingSettingService extends BaseApi {
    private static instance: ShippingSettingService;
    private static store?: string;
    private customHeader = () => {
        const customHeader: Record<string, string> = {};
        if (ShippingSettingService.store) {
            customHeader["x-tenant-slug"] = ShippingSettingService.store;
        }
        return customHeader;
    };

    constructor(store?: string) {
        super();
        ShippingSettingService.store = store;
    }

    public static getInstance(): ShippingSettingService {
        if (!ShippingSettingService.instance) {
            ShippingSettingService.instance = new ShippingSettingService();
        }
        return ShippingSettingService.instance;
    }
    public static resetInstance(store?: string): void {
        this.instance = new ShippingSettingService(store);
    }

    private readonly END_POINT = "v1/shipping-setting";

    public async getShopShippingSettings(
        shopId: string,
    ): Promise<NShippingSetting.IShippingSettingDetail | undefined> {
        const result = await this.get<
            Response<NShippingSetting.IShippingSettingDetail>
        >(`${this.END_POINT}/${shopId}`, {}, this.customHeader());
        return result?.data?.data || undefined;
    }

    public async getEstimateShippingFee(
        request: IGetEstimateShippingFeeRequestDto,
    ): Promise<NShippingSetting.IGetEstimateAhamoveShippingFeeResponseDto[]> {
        const result = await this.get<
            Response<
                NShippingSetting.IGetEstimateAhamoveShippingFeeResponseDto[]
            >
        >(`${this.END_POINT}/estimate`, request as any, this.customHeader());
        console.log(result);

        return result?.data?.data || [];
    }
}
