import BaseApi from "./baseApi.service";
import { NCategory } from "../../interfaces";
import Response from "../../data-access/response/response";

export class CategoryService extends BaseApi {
    private static instance: CategoryService;
    private static store?: string;
    private customHeader = () => {
        const customHeader: Record<string, string> = {};
        if (CategoryService.store) {
            customHeader["x-tenant-slug"] = CategoryService.store;
        }
        return customHeader;
    };

    constructor(store?: string) {
        super();
        CategoryService.store = store;
    }

    public static getInstance(): CategoryService {
        if (!CategoryService.instance) {
            CategoryService.instance = new CategoryService();
        }
        return CategoryService.instance;
    }

    public static resetInstance(store?: string): void {
        this.instance = new CategoryService(store);
    }

    private readonly END_POINT = {
        category: "v1/public/category",
    };

    public async getAllCategories(
        query: NCategory.IGetPublicCategory,
    ): Promise<NCategory.Category[]> {
        const { data } = await this.get<Response<NCategory.Category[]>>(
            this.END_POINT.category,
            query as any,
            this.customHeader(),
        );

        if (data?.data) {
            return data?.data;
        }
        return [];
    }
}
