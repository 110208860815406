export class ShippingAddress {
    id?: string;
    customerId?: string;
    name?: string;
    countryCode?: string;
    postalCode?: number;
    phone?: string;
    city?: string;
    cityId?: string;
    district?: string;
    districtId?: string;
    ward?: string;
    wardId?: string;
    street?: string;
    bookingSlot?: string;
    bookingSlotCode?: string;

    constructor(data?: any) {
        this.id = data?.id || "";
        this.customerId = data?.customer_id || "";
        this.name = data?.first_name || "";
        this.street = data?.address_1 || "";
        this.countryCode = data?.country_code || "";
        this.postalCode = data?.postal_code || "";
        this.phone = data?.phone || "";
        this.city = data?.city || "";
        this.cityId = data?.city_id || "";
        this.district = data?.district || "";
        this.districtId = data?.district_id || "";
        this.ward = data?.ward || "";
        this.wardId = data?.ward_id || "";
        this.bookingSlot = data?.booking_slot || "";
    }
}
