import { NAuth } from "../../interfaces";
import BaseApi from "./baseApi.service";
import Response from "../../data-access/response/response";

export class AuthService extends BaseApi {
    private static instance: AuthService;
    constructor() {
        super();
    }

    public static getInstance(): AuthService {
        if (!AuthService.instance) {
            AuthService.instance = new AuthService();
        }
        return AuthService.instance;
    }

    public static resetInstance(): void {
        this.instance = new AuthService();
    }

    private readonly END_POINT = {
        auth: "v1/auth",
    };

    public async login(
        token: string,
    ): Promise<Response<NAuth.ILoginResponseDto> | null> {
        const { data } = await this.post<Response<NAuth.ILoginResponseDto>>(
            `${this.END_POINT.auth}/pos/login`,
            {
                token: token,
            },
        );

        return data || null;
    }
}
