import { CartItem } from "./cart-item.model";
import { ShippingAddress } from "./shipping-address";

export class Order {
    id: string;
    shippingAddress: ShippingAddress;
    items?: CartItem[];
    status: string;
    cartId: string;
    total: string;
    subtotal: string;
    paymentStatus: string;
    constructor(data?: any) {
        this.id = data?.id || "";
        this.shippingAddress =
            new ShippingAddress(data?.shipping_address) || {};
        this.items = this.buildItems(data?.items);
        this.status = data?.status || "";
        this.cartId = data?.cart_id || "";
        this.total = data?.total || "";
        this.subtotal = data?.subtotal || "";
        this.paymentStatus = data?.payment_status || "";
    }

    private buildItems = (items: any[]): CartItem[] => {
        const result: CartItem[] = [];
        items.forEach((item: any) => {
            result.push(new CartItem(item));
        });
        return result;
    };
}
