import { ShippingAddress } from "./shipping-address";

export class Cart {
    id: string;
    email?: string;
    shippingAddressId?: string;
    shippingAddress?: ShippingAddress;
    items?: any[];
    customerId?: string;
    shippingTotal?: number;
    discountTotal?: number;
    taxTotal?: number;
    subtotal: number;
    total: number;

    constructor(data?: any) {
        this.id = data?.id || "";
        this.email = data?.email || "";
        this.shippingAddressId = data?.shipping_address_id || "";
        this.shippingAddress =
            new ShippingAddress(data?.shipping_address) || {};
        this.items = data?.items || [];
        this.customerId = data?.customer_id || "";
        this.shippingTotal = data?.shipping_total || "";
        this.discountTotal = data?.discount_total || "";
        this.taxTotal = data?.tax_total || "";
        this.subtotal = data?.subtotal || "";
        this.total = data?.total || "";
    }
}
