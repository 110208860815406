import BaseApi from "./baseApi.service";
import { IMarketLocation } from "@interfaces/index";

export class MarketLocationService extends BaseApi {
    private static instance: MarketLocationService;

    constructor() {
        super("market");
    }
    public static getInstance(): MarketLocationService {
        if (!MarketLocationService.instance) {
            MarketLocationService.instance = new MarketLocationService();
        }
        return MarketLocationService.instance;
    }

    public static resetInstance(): void {
        this.instance = new MarketLocationService();
    }

    private readonly END_POINT = "v1/location";

    public async getCities(): Promise<IMarketLocation[]> {
        const res = await this.get<IMarketLocation[]>(
            `${this.END_POINT}/cities`,
        );
        return res.data ?? [];
    }

    public async getDistricts(city: string): Promise<IMarketLocation[]> {
        const res = await this.get<IMarketLocation[]>(
            `${this.END_POINT}/districts/${city}`,
        );
        return res.data ?? [];
    }

    public async getWards(districtId: string): Promise<IMarketLocation[]> {
        const res = await this.get<IMarketLocation[]>(
            `${this.END_POINT}/wards/${districtId}`,
        );
        return res.data ?? [];
    }
}
