export class Collection {
    id: string;
    title: string;
    handle: string;

    constructor(data?: Partial<Collection>) {
        this.id = data?.id || "";
        this.title = data?.title || "";
        this.handle = data?.handle || "";
    }
}
